.App {
  text-align: center;
}

body {
  background-color: #282c34;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
	align-items: center;
	background-color: #282c34;
	box-shadow: 0 30px 90px rgba(91, 76, 76, 0.4);
	color: #FFF;
	display: flex;
	flex-direction: column;
	font-size: calc(10px + 2vmin);
	justify-content: center;
	margin: 15%;
	min-height: 40vh;
}

.App-link {
  color: #61dafb;
}

.heart {
  color: #ff0000;
}

.small {
  font-size: 0.75rem;
}
